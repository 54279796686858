<template>
  <div
    class="has-text-centered"
  >
    <h1 class="title is-size-5">
      Welcome to <Brand />
    </h1>
    <p>
      Thank you for creating and funding your deal! We look forward to helping attract new customers while incentivizing existing customers to keep coming back.
    </p>
    <p v-if="hasScanAwardMethod">
      You enabled the "Scan QR" award method, so please print out your deal's <a @click="printQr()">QR code</a> and keep a copy at each register to present to customers upon completed transactions.
    </p>
    <p v-if="hasClickAwardMethod">
      You enabled the "one-click" award method, so please check your account regularly for any pending in-app deal claims which may need to be reconciled and approved. We suggest confirming claims at the time of purchase, if possible.
    </p>
    <p>You may change your award method in your <a @click="go('/settings-general')">settings</a>.</p>
    <button
      class="button is-link"
      @click="onSubmit"
    >
     View Your Deal
    </button>
  </div>
</template>

<script>
import Brand from "@/components/Brand.vue";

export default {
  components: {
    Brand
  },
  data() {
    return {
      business: {},
      deal: {}
    }
  },
  computed: {
    currentUser: function() {
      return this.$store.getters.currentUser
    },
    currentBusiness: function() {
      return this.$store.getters.currentBusiness
    },
    currentDeal: function() {
      return this.$store.getters.currentDeal
    },
    hasScanAwardMethod: function() {
      return this.business.award_method == "both" || this.business.award_method == "scan"
    },
    hasClickAwardMethod: function() {
      return this.business.award_method == "both" || this.business.award_method == "click"
    }
  },
  mounted() {
    if (this.currentBusiness
        && this.currentBusiness.slug
        && this.currentBusiness.is_onboarded
        && this.currentDeal
        && this.currentDeal.slug
      ) {
      this.business = this.currentBusiness
      this.deal = this.currentDeal

      // clear out any state that was only needed for onboarding
      this.$store.dispatch("clearBusinessAddress")
        .catch(error => {
          console.log(error)
        })
    } else {
      this.$router.push("/o/organization-new")
    }
  },
  methods: {
    go(path) {
      this.$router.push(path);
    },
    printQr() {
      const locationId = this.business.locations[0]["id"]
      const path = `/deal/${this.deal.business.slug}/${this.deal.slug}/l/${locationId}/print-qr`
      this.$router.push(path)
    },
    onSubmit() {
      this.$router.push('/deals/user/my-location/100')
    }
  }
}
</script>

<style scoped>
p, button {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
